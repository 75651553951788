// eslint-disable-next-line no-restricted-imports
import '@/features/course/Course.css';
import { useAppSelector } from '@/store/hooks';
import { Link } from 'react-router-dom';
import { mode } from '../utils/mode';
import { LogoDiplomatura } from '@/features/course';

export const Hero = () => {
  const { course, price } = useAppSelector(state => state.course);
  const { userIP } = useAppSelector(state => state.userIP);
  const str = course?.code;
  const is_ciberseguridad = str?.includes('CIBER');
  const is_ia = str?.includes('cIA');
  let logos;
  let logos2;
  if (is_ciberseguridad) {
    logos = <LogoDiplomatura tipo="usil" />;
  } else if (is_ia) {
    logos = <LogoDiplomatura tipo="mundose" />;
  } else {
    logos = <LogoDiplomatura tipo="fcefyn" />;
    logos2 = <LogoDiplomatura tipo="unc" />;
  }
  return (
    <section className="course_hero d-flex align-items-center shadow-border pb-5 lighter-grey_bg">
      <div className="container">
        <figure className="row">
          <figcaption className="col-md-6 d-flex flex-column justify-content-between align-items-start">
            <em className="course_icon">
              <img
                src={`/img/${
                  course?.mode === 'online' ? 'image_online_icon.svg' : 'onDemand_icon.svg'
                }`}
                alt="..."
                width="80px"
              />
            </em>
            <h5 className="my-3 caption">modalidad {course.mode}</h5>
            <h1 className="mb-3 pe-4">{course.fullname}</h1>
            <p className="mb-4 lead pe-4">{course.description || 'description'}</p>
            <article className="course-value mt-2 pt-2 gap-3 d-flex flex-column">
              {price && mode[course?.mode]?.allowBuy && price.listprice && (
                <div className="bordered gap-3">
                  {/* <p className="m-0">
                    <small className="value d-block">$11.568</small>
                    <small className="discount red d-block">70% dto</small>
                  </p> */}
                  <h2
                    className="m-0 price"
                    style={{
                      padding: '0.5rem 0',
                    }}>
                    {mode[course?.mode]?.allowBuy &&
                      price?.listprice &&
                      `$ ${price?.listprice} ${
                        userIP?.countryCurrencySimbolo === 'ARS' ? 'ARS' : 'USD'
                      }`}
                  </h2>
                </div>
              )}
              <Link
                to={
                  mode[course?.mode]?.allowBuy && Object.keys(price).length > 0
                    ? `/programas/${course.id}/compra`
                    : `/programas/${course.id}/consulta`
                }
                className="btn primary-btn btn-rounded red_bg white col-12 col-md-6 mt-4 w-100"
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  minWidth: course?.mode === 'online' ? '300px' : null,
                }}>
                {' '}
                {mode[course?.mode]?.allowBuy && Object.keys(price).length > 0
                  ? 'comprar'
                  : 'Consultar'}
              </Link>

              {/* <button className="btn primary-btn btn-rounded red_bg white col-12 col-md-6 mt-4">Comprar</button> */}
            </article>
          </figcaption>
          <div className="col-md-6">
            <div className="course_img mb-5">
              <img
                className="img-fluid "
                src={course?.image && 'data:image/png;base64,' + course?.image?.data}
                alt={course?.fullname}
              />
            </div>
            <div className="certificacion d-flex align-items-center py-2 gap-3 justify-content-center">
              <h5 className="m-0 caption">DIPLOMATURAS UNIVERSITARIAS</h5>
              {logos}
              {logos2}
            </div>
          </div>
        </figure>
      </div>
    </section>
  );
};
